<template>
  <div class="address">
    <NavTop navType="fun_text" textCon="新增地址">
      <template v-slot:rightText>
        <span @click="saveCon">保存</span>
      </template>
    </NavTop>
    <div class="text-con">
      <Field
        label="收货人"
        v-model="name"
        placeholder="请填写收货人姓名"
      ></Field>
      <Field
        label="联系电话"
        v-model="mobel"
        type="number"
        placeholder="请填写收货人姓名联系电话"
      ></Field>
      <Field
        label="所在地区"
        v-model="region"
        placeholder="请选择"
        arrow-direction
        disabled
        @click="showArea = true"
      ></Field>
      <Field
        label="详细地址"
        v-model="detail"
        type="textarea"
        rows="3"
        placeholder="街道、楼牌等，限50字内"
        maxlength="50"
      ></Field>
    </div>
    <div class="address-con">
      <div class="address-top">
        <span>地址标签</span>
        <div class="address-label">
          <span
            :class="[makeNum == idx ? 'active' : '']"
            v-for="(item, idx) in addressLabel"
            :key="idx"
            @click="chooseLabel(idx)"
            >{{ item }}</span
          >
        </div>
      </div>
      <div class="address-bottom">
        <div>
          <span>设置默认地址</span>
          <br />
          <span>提醒：提醒：每次下单会默认推荐使用该地址</span>
        </div>
        <div class="address-right">
          <Van-Switch
            v-model="initAddress"
            inactive-color="#999999"
            active-color="#FF8000"
            size="18px"
            @change="makeTrue(initAddress)"
          />
        </div>
      </div>
    </div>
    <div class="del-btn" @click="delAddress" v-if="status">
      <span>删除该地址</span>
    </div>
    <Popup v-model="showArea" position="bottom" :style="{ height: '40%' }">
      <Area
        :area-list="areaList"
        @change="getProvince"
        @cancel="showArea = false"
        @confirm="showArea = !showArea"
      />
    </Popup>
  </div>
</template>
<script>
import Filter from "@/lin/filter/time"
import Address from "@api/address";
import { Field, Switch, Popup, Area } from "vant";
import NavTop from "@components/public/searchTop";
import areaList from "../../../public/static/js/area"
export default {
  components: {
    Field,
    NavTop,
    "Van-Switch": Switch,
    Popup,
    Area,
  },
  data() {
    return {
      name: "",
      mobel: "",
      region: "",
      detail: "",
      id: 0,
      diytag: "",
      showTag: true,
      status: false,
      makeNum: 0,
      area_id: 0,
      areaList: areaList,
      showArea: false,
      initAddress: true,
      addressLabel: ["家", "公司", "学校"],
      columns: []
    };
  },
  methods: {
    chooseLabel(idx) {
      this.makeNum = idx;
      if (idx == 3){
        this.showTag = true;
      }
    },
    // 滑动开关
    makeTrue(initAddress) {
      let msg = this.initAddress
        ? "您确定设置该地址为默认地址吗？"
        : "确定取消该地址为默认地址吗？";
      this.$dialog
        .confirm({
          title: "提醒",
          message: msg
        })
        .then(() => {
          this.initAddress = initAddress;
        })
        .catch(() => {
          this.initAddress = !initAddress;
        });
    },
    // 获取省市
    async getProvince(picker, values) {
      let adddress = `${values[0].name}/${values[1].name}/${values[2].name}`;
      this.area_id = values[2].code;
      this.region = adddress;
    },
    // 删除地址
    async delAddress() {
      try {
        const res = await Address.delAddress(this.id);
        console.log(res);
        if (res.code == 200) {
          this.$toast("删除成功");
          this.$router.go(-1);
          // this.$nextTick(() => {
          //   this.getList();
          // }, 100);
        } else {
          this.$toast("删除失败");
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 保存地址
    async saveCon() {
      if (this.status&&Filter.checkPhone(this.mobel)) {this.changAddress();} 
      else {try {
        if (!this.name) return this.$toast("请输入联系人姓名..");
        if (!this.mobel) return this.$toast("请输入联系人手机号..");
        if (!this.detail) return this.$toast("请输入详细地址..");
        const res = await Address.saveAddress(
          parseInt(this.area_id),
          this.detail,
          this.name,
          this.mobel,
          this.initAddress ? "1" : "2",
          this.addressLabel[this.makeNum]
        );
        console.log(res);
        if (res.code == 200) {
          this.$toast(res.msg);
          this.$router.go(-1);
        }
      } catch (e) {
        console.log(e);
      }}
      
    },
    // 获取详细地址信息
    async getDetInfo() {
      try {
        const res = await Address.getAddressDet(this.id);
        console.log(res);
        if (Object.keys(res).length > 0) {
          const { area_id, address, name, mobile, is_default, area_name } = res;
          this.area_id = parseInt(area_id);
          this.name = name;
          this.mobel = mobile;
          this.detail = address;
          (this.initAddress = is_default == "1" ? true : false),
            (this.region = area_name);
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 修改地址
    async changAddress() {
      try {
        const res = await Address.editAddress(
          this.id,
          this.area_id,
          this.detail,
          this.name,
          this.mobel,
          this.initAddress ? "1" : "2",
          this.addressLabel[this.makeNum]
        );
        if (res.code==200) {
          this.$toast(res.msg);
          this.$router.go(-1);
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.status = this.$route.query.change;
    this.id = this.$route.query.id;
    this.$nextTick(() => {
      if (this.status || this.status == "true") {
        this.getDetInfo();
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.address {
  width: 100%;
  height: 100vh;
  padding-top: 50px;
  background: #f4f4f4;
  .address-nav {
    width: 100%;
    height: 45px;
    line-height: 45px;
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
    background: #ffffff;
    span:nth-child(1) {
      font-size: 16px;
      color: #333333;
    }
    span:nth-child(2) {
      font-size: 16px;
      color: #333333;
    }
    span:nth-child(3) {
      font-size: 14px;
      color: #ff8000;
    }
  }
  .text-con {
    width: 90%;
    height: 225px;
    margin: 10px auto;
    border-radius: 10px;
    overflow: hidden;
  }
  .del-btn {
    width: 90%;
    height: 46px;
    color: #ff8000;
    line-height: 46px;
    text-align: center;
    margin: 20px auto;
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
  }
  .address-con {
    width: 90%;
    height: auto;
    display: flex;
    margin: 0 auto;
    padding: 0 10px;
    border-radius: 10px;
    background: #ffffff;
    flex-direction: column;
    justify-content: space-between;
    .address-top {
      width: 100%;
      height: 50%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f4f4f4;
      .address-label {
        width: 80%;
        height: 50%;
        padding: 10px 0;
        margin-left: 10px;
        span {
          width: 55px;
          height: 22px;
          display: inline-block;
          color: #333333;
          font-size: 14px;
          border-radius: 12px;
          border: 1px solid #d6d6d6;
          text-align: center;
          margin: 2px;
        }
        .active {
          color: #ff8000;
          border: 1px solid #ff8000;
        }
      }
    }
    .address-bottom {
      width: 100%;
      height: 50%;
      padding: 8px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
